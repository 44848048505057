/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"

import Header from "./header"
import Footer from './footer'

import { AppContextProvider } from "./context";

//import "./layout.css"

const Layout = ({ children, intl, pageName, siteTitle, intro }) => {
	const contextVal = {page: pageName}
	return (
		<>
			<AppContextProvider value={contextVal}>
				<Header
					siteTitle={siteTitle ? intl.formatMessage({ id: siteTitle }) : ""}
					intro={intro ? intl.formatMessage({ id: intro }) : ""}
				/>
				<main className="topography-bg" >
					{children}
				</main>
				<Footer />
			</AppContextProvider>
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
