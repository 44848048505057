import React, { useContext } from "react"
import AppContext from './context';

import NavMain from "./navMain";
import Video from './video';
import HeaderInner from './headerInner';

import '../scss/style.scss';

const Header = ({ siteTitle, intro }) => { 
	const context = useContext(AppContext)
	return (
		<header className={context.page}>
			<NavMain />
			{context.page === "home" ? <Video /> : <HeaderInner siteTitle={siteTitle} intro={intro} />}
		</header>
	)
}

export default Header
