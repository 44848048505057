import React from 'react'
import '../scss/footer.scss'
import { injectIntl, Link } from "gatsby-plugin-intl"

import arrowRight from '../images/arrow-right.svg';

import phoneIcon from '../images/phone-icon.svg';
import envelopeIcon from '../images/envelope-icon.svg';
import fbIcon from '../images/fb-icon.svg';
import instaIcon from '../images/insta-orange.svg';
import calendarIcon from '../images/calendar-icon.png';

const Footer = ({ intl }) => (
	<footer>
		<div className="cta wrapper container">
			<div className="ctaContent center">
				<img src={calendarIcon} className="icon icon--large" />
				<h2>{intl.formatMessage({ id: "footer.reserve" })}</h2>
				<Link className="center arrow btn fatLink" to="/contact/">
					<span>{intl.formatMessage({ id: "footer.contact" })}</span>
					<img src={arrowRight} /> 
				</Link>
			</div>
		</div>
		<div className="footterBottom wrapper container">
			<ul className="footerContact">
				{/* <li><a href="tel:+351910033399"><img className="icon" src={phoneIcon} /><span>+351 910 033 399</span></a></li> */}
				<li><a href="mailto:dragon.tree.travel@gmail.com"><img className="icon" src={envelopeIcon} /><span>dragon.tree.travel@gmail.com</span></a></li>
				<li><a href="https://www.facebook.com/Dragon.Tree.Travel"><img className="icon" src={fbIcon} /></a></li>
				<li><a href="https://www.instagram.com/madera_dragon.tree.travel/"><img className="icon" style={{width:"1.2em"}} src={instaIcon} /></a></li>
			</ul>
		</div>
	</footer>
)

export default injectIntl(Footer);