import React, { useState, useEffect } from 'react';
import '../scss/video.scss';
import ReactPlayer from 'react-player'
import video from '../video/madera-filtr.mp4';

const videos = [
	{
		src: video,
		type: 'video/mp4'
	}
];

const playVideo = () => {
	document.querySelector('video').play();
	const playBtn = document.querySelector('#playBtn');

	playBtn.parentNode.removeChild(playBtn);
}

const Video = ({ videoSrcURL, videoTitle, ...props }) => {
	const [autoPlay, setAutoPlay] = useState(true);

	useEffect(() => {
		var myVideo = document.querySelector('video');
		myVideo.addEventListener("contextmenu", function (e) { e.preventDefault(); e.stopPropagation(); }, false);
	
		// hide the controls if they're visible
		if (myVideo.hasAttribute("controls")) {
			myVideo.removeAttribute("controls")
		}
	}, []);

	const ifAutoPlayEnabled = () => {
		var promise = document.querySelector('video').play();
		if (promise !== undefined) {
			promise.then(_ => {
				console.log("yes")
				setAutoPlay(true)
				// Autoplay started!
			}).catch(error => {
				console.log("no")
				setAutoPlay(false)
				// Autoplay was prevented.
				// Show a "Play" button so that user can start playback.
			});
		}
	}
	return (
		<>
			<ReactPlayer
				className="video-bg"
				width="100%"
				height="100%"
				autoPlay
				muted={true}
				volume={0}
				playing={true}
				controls={false}
				playsinline={true}
				webkit-playsinline={true}
				url={videos}
				loop={true}
				onReady={() => { ifAutoPlayEnabled() }}
			/>
			{!autoPlay &&
				<button type="button" id="playBtn" className="play btn" onClick={playVideo}>Play</button>
			}
			
		</>
	)
}

export default Video;