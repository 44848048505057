import React from "react"

const HeaderInner = ({ siteTitle, intro }) => { 
	return (
		<div className="header-inner center wrap">
			<h1 className="siteTitle">{siteTitle}</h1>
			<p className="intro">{intro}</p>
		</div>
	)
}

export default HeaderInner;
